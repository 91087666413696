import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function Blog(){
    return(
    <div>
        <h1>Blogs Coming Soon!</h1>
        <Container>
            <Row>
                <Col>
                </Col>
            </Row>
        </Container>
        
       
    </div>
    )
}
export default Blog;